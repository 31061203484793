import "./styles/app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import BlogList from "./pages/Blog/BlogList";
import BlogContent2 from "./pages/Blog/BlogContent2";
import BlogContent3 from "./pages/Blog/BlogContent3";
import BlogContent4 from "./pages/Blog/BlogContent4";
import BlogContent5 from "./pages/Blog/BlogContent5";
import BlogContent6 from "./pages/Blog/BlogContent6";
import BlogContent from "./pages/Blog/BlogContent";
import ComingSoon from "./pages/ComingSoon";
import TBBS from "./pages/TBBS";

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" exact element={<ComingSoon />} />
          <Route path="/tbbs" exact element={<TBBS />} />
          <Route path="/tbbs/:id" exact element={<TBBS />} />
          {/* <Route path="/" exact element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blog" element={<BlogList />} />
          <Route exact path="/blog/top-tips-for-a-beautiful-food-waste-bin" element={<BlogContent />} />
          <Route exact path="/blog/why-kerala-floods-were-the-worst-in-a-century" element={<BlogContent2 />} />
          <Route exact path="/blog/rainwater-harvesting-system-in-tn-mans-home" element={<BlogContent3 />} />
          <Route exact path="/blog/man-shows-how-to-set-up-a-rainwater-harvesting-system" element={<BlogContent4 />} />
          <Route exact path="/blog/water-crisis" element={<BlogContent5 />} />
          <Route exact path="/blog/couple-recycles-10000-tonnes-of-waste" element={<BlogContent6 />} /> */}
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect } from "react";

const withTitle = (Component, title) => (props) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return <Component {...props} />;
};

const ComingSoon = () => {
  return (
    <div>
      <div className="coming-soon" style={styles.comingSoon}>
        <h1>Coming Soon</h1>
      </div>
      <a href="https://shagalsajid.com">shagalsajid.com</a>
    </div>
  );
};

const styles = {
  comingSoon: {
    backgroundColor: "#f2f2f2",
    margin: 0,
    height: "100vh",
    display: "flex", // Use flexbox
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
  },
  h1: {
    fontSize: "5rem",
    marginBottom: "2rem",
  },
  a: {
    textDecoration: "none",
    color: "#333",
    fontSize: "1.5rem",
  },
};

export default withTitle(ComingSoon, "Coming Soon - shagalsajid.com");

import React, { useEffect } from "react";

const withTitle = (Component, title) => (props) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return <Component {...props} />;
};

const ComingSoon = () => {
  return (
    <div>
      <div className="coming-soon" style={styles.comingSoon}>
        <h1>The Beyond Busy Show</h1>
        <ul>
          <li>
            <a href="https://podcasts.apple.com/in/podcast/the-beyond-busy-show/id1723689274">
              Apple Podcast
            </a>
          </li>
          <li>
            <a href="https://open.spotify.com/show/3p2eX4xtbRSgWMw44IcoY4?si=30e8cd4fbaca4610">
              Spotify
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const styles = {
  comingSoon: {
    backgroundColor: "#f2f2f2",
    margin: 0,
    height: "100vh",
    display: "flex", // Use flexbox
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
  },
  h1: {
    fontSize: "5rem",
    marginBottom: "2rem",
  },
  a: {
    textDecoration: "none",
    color: "#333",
    fontSize: "1.5rem",
  },
};

export default withTitle(ComingSoon, "Coming Soon - shagalsajid.com");
